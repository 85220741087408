<template>
    <div v-if="showCard && adversary && Object.keys(adversary).length">
      <v-card
        v-if="display.smAndDown.value"
        color="primary"
      >
        <v-card-text class="mk-text--body text-left mt-2">
          <p>
            <b>{{`${adversary.display_name}`}}: Cohort Results Publication</b>
          </p>
          <p>
            MITRE is excited to publish {{`${adversary.display_name}`}} cohort results.
          </p>
          <v-btn
            width=100%
            class="mk-button--primary-standard mt-4"
            :to="`/results/${adversary.domain.toLowerCase()}?view=cohort&evaluation=${adversary.name}&result_type=DETECTION&scenarios=${scenarios}`"
          >
            View Results
          </v-btn>
        </v-card-text>
      </v-card>
      <div v-else class="cfp-card">
        <div class="mb-4 cfp-btn-div">
          <v-btn
            color="background"
            icon="mdi-close"
            size="small"
            @click="closeCard()"
          />
        </div>
        <v-card
          color="background"
        >
          <v-card-title>
            <v-card
              color="background"
              border="sm"
              flat
            >
              <v-img
                class="ma-auto"
                :src="$imageSrc + adversary.logo_image"
                max-width="250"
                width="100%"
                contain
              />
              <div>
                <span class="mk-text--h2">{{ adversary.display_name }}</span>
              </div>
              <span class="c-text--primary mk-text--body">Cohort Results Publication</span>
            </v-card>
          </v-card-title>
          <v-card-text class="mk-text--body text-left mt-4">
            <p>
              MITRE is excited to publish {{`${adversary.display_name}`}} cohort results.
            </p>
            <p class="mt-2">
              {{ adversary.description }}
            </p>
            <v-btn
              width=100%
              class="mt-4 mk-button--primary-standard"
              color="primary"
              :to="`/results/${adversary.domain.toLowerCase()}?view=cohort&evaluation=${adversary.name}&result_type=DETECTION&scenarios=${scenarios}`"
            >
              View Results
            </v-btn>
            <v-btn
              width=100%
              class="mk-button--primary-link mt-4"
              color="background"
              flat
              :to="`/${adversary.domain.toLowerCase()}/${adversary.name}/`"
            >
              Learn More
            </v-btn>
          </v-card-text>
        </v-card>
      </div>
    </div>
  </template>

  <script setup>
    import { computed, ref, watch } from 'vue';
    import { useStore } from 'vuex';
    import { useDisplay } from 'vuetify';

    const adversary = ref([]);
    const display = useDisplay();
    const showCard = ref(true);
    const store = useStore();

    const allAdversaries = computed(() => store.getters.allAdversaries);

    const scenarios = computed(() => {
      if(adversary.value && Object.keys(adversary.value).length) {
        return adversary.value.scenarios.filter(s => s.scenario_type === "DETECTION").map(s => s.number).join(",");
      }
      return "";
    })

    function closeCard() {
      showCard.value = !showCard.value;
    }

    watch(allAdversaries, (newVal) => {
    if (newVal && Object.keys(newVal).length !== 0) {
      adversary.value = allAdversaries.value.find(adv => adv.name === "er6");
    }
  }, { immediate: true });
  </script>

  <style lang="scss" scoped>
    @import "../../sass/pages/_cfp-alert.scss";
  </style>